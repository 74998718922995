import apiClient from './config'
import store from './../store'

if (store.state.user) {
    apiClient.defaults.headers.common['Authorization'] = store.state.user.token
}

export default {
    all(data) {
        return apiClient.post('/groups', data)
    },

    save(data) {
        return apiClient.post('/groups/save', data)
    },

    setImage(data) {
        return apiClient.post('/groups/setimage', data)
    },
    remove(data) {
        return apiClient.post('/groups/remove', data)
    },
}

