import apiClient from './config'
import store from './../store'

if (store.state.user) {
    apiClient.defaults.headers.common['Authorization'] = store.state.user.token
}

export default {
    all(data) {
        return apiClient.post('/lightboxes', data)
    },

    others(data) {
        return apiClient.post('/lightboxes/others', data)
    },

    findLast(data) {
        return apiClient.post('/lightboxes/find_last', data)
    },

    save(data) {
        return apiClient.post('/lightboxes/save', data)
    },

    remove(data) {
        return apiClient.post('/lightboxes/remove', data)
    },

    send(data) {
        return apiClient.post('/lightboxes/send', data)
    },

    items(data) {
        return apiClient.post('/lightboxes/items', data)
    },

    allItems(data) {
        return apiClient.post('/lightboxes/all_items', data)
    },

    addItem(data) {
        return apiClient.post('/lightboxes/add_item', data)
    },

    swapItem(data) {
        return apiClient.post('/lightboxes/swap_item', data)
    },

    removeItem(data) {
        return apiClient.post('/lightboxes/remove_item', data)
    },

    findExt(data) {
        return apiClient.post('/lightboxes/find_ext', data)
    },

    itemsExt(data) {
        return apiClient.post('/lightboxes/items_ext', data)
    },
}
