export default {
    data() {
        return {
            currentIndex: 0,
            showPrev: false,
            showNext: false,
        }
    },

    methods: {
        details(image) {
            this.dialog = true

            this.imageDetails = image
            this.remarkDetails.remark = ''

            this.imageDetails.extension = this.imageDetails.image_file.split('.').pop().toLowerCase()

            // Find index of this image
            this.currentIndex = this.images.findIndex(image => image.id === this.imageDetails.id)

            this.showPrev = true
            this.showNext = true

            if (this.currentIndex == 0) {
                this.showPrev = false
            }
            if (this.currentIndex == (this.images.length - 1)) {
                this.showNext = false
            }
        },

        prev() {
            let prevImage = this.images[this.currentIndex - 1]
            this.details(prevImage)
        },

        next() {
            let nextImage = this.images[this.currentIndex + 1]
            this.details(nextImage)
        },
    },

}
