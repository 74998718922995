import apiClient from './config'
import store from './../store'

if (store.state.user) {
    apiClient.defaults.headers.common['Authorization'] = store.state.user.token
}

export default {
    all(data) {
        return apiClient.post('/images', data)
    },

    splash(data) {
        return apiClient.post('/images/splash', data)
    },

    find(data) {
        return apiClient.post('/images/find', data)
    },

    update(data) {
        return apiClient.post('/images/update', data)
    },

    uneditedFind(data) {
        return apiClient.post('/images/unedited_find', data)
    },

    uneditedUpdate(data) {
        return apiClient.post('/images/unedited_update', data)
    },

    remove(data) {
        return apiClient.post('/images/remove', data)
    },
}
