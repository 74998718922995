<template>
    <v-container>
      <div v-if="(page=='lightbox' || page=='lightbox_ext') &&  selectedGroup===null">
      <v-row v-for="group in displayGroups"
             :key="group.id">
        <v-col cols="4">
          <a @click="showGroup(group)" title="{{ (page=='lightbox' ? $t('show_images') : $t('login_to_show_images')) }}">
            <v-card style="height:100%">
              <v-img :src="'thumbnails/' + group.thumbnail"
                     aspect-ratio="1"

              ></v-img>

              <v-card-actions>
                        <span class="caption font-italic font-weight-light text-truncate">
                            {{ group.name }}
                        </span>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </a>
        </v-col>
        <v-col cols="8">
          <v-card style="height:100%;position:relative;">
            <v-card-title>{{ group.name }}</v-card-title>
            <v-card-subtitle class="text-truncate">
              {{ group.cultivar }} - {{ group.variety }}
            </v-card-subtitle>
            <v-card-text style="white-space: pre;">{{ group.description }}</v-card-text>
            <v-card-actions style="position: absolute;bottom:0;">
              <v-btn color="info" @click="showGroup(group)" >
                {{ (page=='lightbox' ? $t('show_images') : $t('login_to_show_images')) }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
      <div v-if="(page!=='lightbox' && page!=='lightbox_ext') ||  selectedGroup!==null">
        <div v-if="selectedGroup!==null">
          <h1>{{selectedGroup.name}}</h1>
          <div>
            <v-btn color="info" @click="clearGroup()" >
              {{ $t('back') }}
            </v-btn>
          </div>
        </div>


        <v-row>
            <v-col cols="2"
                   v-for="image in displayImages"
                   :key="image.id"
            >
                <v-card>
                    <v-img :src="'thumbnails/' + image.thumbnail"
                           aspect-ratio="1"
                           @click="details(image)"
                    ></v-img>

                    <v-card-actions>
                        <span class="caption font-italic font-weight-light text-truncate">
                            {{ image.brand }} {{ image.variety }}
                        </span>

                        <v-spacer></v-spacer>

                        <span v-if="searchPage">
                            <v-btn v-if="!isOnLightbox(image)" text icon color="red" @click="showLightboxes(image)">
                                <v-icon>mdi-heart-outline</v-icon>
                            </v-btn>

                            <v-btn v-if="isOnLightbox(image)" text icon color="red" @click="swapLightboxes(image)">
                                <v-icon>mdi-heart</v-icon>
                            </v-btn>
                        </span>

                        <span v-if="lightboxPage">
                            <v-btn text icon color="grey" @click="swapLightboxes(image)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </span>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-if="nextBatchButton">
            <v-col>
                <v-btn block color="info" @click="nextBatch">
                    {{ $t('more_results') }}
                </v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog" persistent width="75%">
            <v-card>
                <v-card-title>{{ $t('image_details') }}</v-card-title>

                <v-card-text>
                    <v-row v-if="isVisitor || isIntern || lightboxExtPage">
                        <v-col cols="6">
                            <v-img :src="'previews/' + imageDetails.preview"></v-img>

                            <download-links :extension="imageDetails.extension" :token="imageDetails.token" class="mt-8"></download-links>
                        </v-col>

                        <v-col cols="6">
                            <div>{{ $t('id') }}: <b>{{ imageDetails.id }}</b></div>
                            <div>{{ $t('brand') }}: <b>{{ imageDetails.brand }}</b></div>
                            <div>{{ $t('variety') }}: <b>{{ imageDetails.variety }}</b></div>
                            <div>{{ $t('type') }}: <b>{{ imageDetails.name }}</b></div>
                            <div>{{ $t('cultivar') }}: <b>{{ imageDetails.cultivar }}</b></div>
                            <div>{{ $t('code') }}: <b>{{ imageDetails.code }}</b></div>
                            <div>{{ $t('photography') }}: <b>{{ imageDetails.photography }}</b></div>
                            <div>{{ $t('shot') }}: <b>{{ imageDetails.shot }}</b></div>
                            <div>{{ $t('keywords') }}: <b>{{ imageDetails.keywords }}</b></div>

                            <div v-if="isVisitor || isIntern">
                                <div v-if="isIntern">
                                    <div>{{ $t('published') }}: <b>{{ imageDetails.published }}</b></div>
                                    <div>{{ $t('category') }}: <b>{{ imageDetails.category }}</b></div>
                                    <div>{{ $t('document_group') }}: <b>{{ imageDetails.document_group }}</b></div>
                                </div>

                                <v-divider class="mt-8 mb-8"></v-divider>

                                <v-form @submit.prevent="addRemark">
                                    <v-textarea
                                            :label="$t('remark')"
                                            v-model="remarkDetails.remark"
                                    />

                                    <v-btn type="submit" color="info">
                                        <v-icon dark left>mdi-file-edit</v-icon>
                                        {{ $t('add_remark') }}
                                    </v-btn>
                                </v-form>
                            </div>
                        </v-col>
                    </v-row>

                    <v-form @submit.prevent="save">
                        <v-row v-if="isAdmin">
                            <v-col cols="4">
                                <v-img :src="'previews/' + imageDetails.preview"></v-img>

                                <div v-if="imageDetails.extension == 'pdf'" class="mt-4">
                                    <vue-dropzone ref="dropzone" id="dropzone"
                                                  :options="dropzoneOptions"
                                                  v-on:vdropzone-sending="sendingEvent"
                                    ></vue-dropzone>
                                </div>

                                <download-links :extension="imageDetails.extension" :token="imageDetails.token" class="mt-8"></download-links>
                            </v-col>

                            <v-col cols="4">
                                <v-text-field :label="$t('brand')" v-model="imageDetails.brand"/>
                                <v-text-field :label="$t('variety')" v-model="imageDetails.variety"/>
                                <v-text-field :label="$t('type')" v-model="imageDetails.name"/>
                                <v-text-field :label="$t('cultivar')" v-model="imageDetails.cultivar"/>
                                <v-text-field :label="$t('code')" v-model="imageDetails.code"/>

                                <v-autocomplete
                                        :label="$t('document_group')"
                                        :items="documentGroups"
                                        :value="imageDetails.document_group"
                                        v-model="imageDetails.document_group"
                                >
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="4">
                                <v-autocomplete
                                        :label="$t('published')"
                                        :items="getPublished"
                                        :value="imageDetails.published"
                                        v-model="imageDetails.published"
                                >
                                </v-autocomplete>

                                <v-autocomplete
                                        :label="$t('category')"
                                        :items="getCategories"
                                        :value="imageDetails.category"
                                        v-model="imageDetails.category"
                                >
                                </v-autocomplete>

                                <v-text-field :label="$t('photography')" v-model="imageDetails.photography"/>
                                <v-text-field :label="$t('keywords')" v-model="imageDetails.keywords"/>

                                <v-autocomplete
                                        :label="$t('shot')"
                                        :items="getShots"
                                        :value="imageDetails.shot"
                                        v-model="imageDetails.shot"
                                >
                                </v-autocomplete>

                              <div>{{ $t('imagegroup') }}: <b>{{ imageDetails.imagegroup.name }}</b></div>
                              <div v-if="imageDetails.imagegroup.image_id!=imageDetails.id">
                                <v-btn type="button" color="warning" v-on:click="markImageAsGroupImage()">
                                  <v-icon dark left>mdi-file-edit</v-icon>
                                  {{ $t('make_imagegroup_image') }}
                                </v-btn>
                              </div>
                              <div v-if="imageDetails.imagegroup.image_id==imageDetails.id">
                                <v-btn type="button" color="">
                                  {{ $t('is_imagegroup_image') }}
                                </v-btn>
                              </div>



                            </v-col>
                        </v-row>
                      <v-row v-if="isAdmin">
                        <v-spacer></v-spacer>

                        <v-btn id="deleteButton" color="error" @click="areyousure = true">
                          <v-icon dark left>mdi-delete-alert</v-icon>
                          {{ $t('delete') }}
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-btn type="submit" color="info">
                          <v-icon dark left>mdi-send</v-icon>
                          {{ $t('save') }}
                        </v-btn>

                        <v-spacer></v-spacer>
                      </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn :disabled="!showPrev" text
                           @click="prev"
                           v-shortkey="['arrowleft']" @shortkey="prev"
                    >
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-btn color="error"
                           @click="dialog = false"
                           v-shortkey="['esc']" @shortkey="dialog = false"
                    >
                        <v-icon dark left>mdi-cancel</v-icon>
                        {{ $t('cancel') }}
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-btn :disabled="!showNext" text
                           @click="next"
                           v-shortkey="['arrowright']" @shortkey="next"
                    >
                        <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="areyousure" persistent width="25%">
            <v-card>
                <v-card-title>{{ $t('are_you_sure') }}</v-card-title>

                <v-card-text>
                    {{ $t('are_you_sure_delete_image') }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue" text
                           @click="areyousure = false"
                           v-shortkey="['esc']" @shortkey="areyousure = false"
                    >
                        {{ $t('cancel') }}
                    </v-btn>

                    <v-btn color="blue" text @click="remove">{{ $t('ok') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="lightboxDialog" persistent width="50%">
            <v-card>
                <v-card-title>{{ $t('select_lightbox') }}</v-card-title>

                <v-card-text>
                    <v-radio-group v-model="selectedLightbox">
                        <v-radio
                            v-for="lightbox in lightboxes"
                            :key="lightbox.id"
                            :label="lightbox.name"
                            :value="lightbox"
                        ></v-radio>
                    </v-radio-group>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error"
                           @click="lightboxDialog = false"
                           v-shortkey="['esc']" @shortkey="lightboxDialog = false"
                    >
                        {{ $t('cancel') }}
                    </v-btn>

                    <v-btn color="info" @click="addItem">{{ $t('ok') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="swapDialog" persistent width="50%">
            <v-card>
                <v-card-title>{{ $t('select_lightbox') }}</v-card-title>

                <v-card-text>
                    <v-radio-group v-model="selectedLightbox">
                        <v-radio
                            v-for="lightbox in otherLightboxes"
                            :key="lightbox.id"
                            :label="lightbox.name"
                            :value="lightbox"
                        ></v-radio>
                    </v-radio-group>
                </v-card-text>

                <v-card-actions>
                    <v-btn color="error"
                           @click="swapDialog = false"
                           v-shortkey="['esc']" @shortkey="swapDialog = false"
                    >
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn color="success" @click="swapItem">{{ $t('swap') }}</v-btn>

                    <v-spacer></v-spacer>
                    <v-btn color="info" @click="removeItem">{{ $t('delete') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar id="snackbar" :color=snackbarColor :top=snackbarTop v-model="snackbar">
            {{ snackbarMessage }}

            <v-btn text @click="snackbar=false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
    </v-container>
</template>

<script>
    import {mapState, mapGetters} from 'vuex'

    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    import imageService from '@/services/ImageService.js'
    import lightboxService from '@/services/LightboxService'
    import remarkService from '@/services/RemarkService'
    import groupService from '@/services/GroupService'

    import downloadLinks from '@/components/DownloadLinks'
    import ArrowKeys from '@/mixins/ArrowKeys'

    export default {
        name: 'ImagesList.vue',
        props: ['images','groups', 'page', 'docs'],
        components: {
            downloadLinks,
            vueDropzone: vue2Dropzone
        },
        mixins: [ArrowKeys],

        computed: {
            ...mapState(['isAdmin', 'isIntern', 'isVisitor', 'lightbox', 'user']),
            ...mapGetters(['getDocumentGroups', 'getDocumentGroupsBranding', 'getShots', 'getPublished', 'getCategories', 'getBatchSize']),

            displayGroups() {
              return this.groups
            },
            numberOfGroups() {
              return this.groups.length
            },

            displayImages() {
                // We use a computed value because we want to mutate the prop
                if (this.searchPage) {
                    return this.images.slice(0, this.batchSize)
                }
                if(this.$route.name!=='lightbox_ext' && this.$route.name!='lightboxes'){
                  return this.images
                }
                if(this.selectedGroup===null){
                  return [];
                }
                let images = [];
                let that = this;
                this.images.forEach(function(image){
                  if(image.group.id == that.selectedGroup.id){
                    images.push(image)
                  }
                });
                return images
            },
            numberOfImages() {
                return this.images.length
            },
            nextBatchButton() {
                if ((this.batchSize >= this.numberOfImages) || (!this.searchPage)) {
                    return false
                }
                return true
            },
        },

        watch: {
            images: function(newValue, oldValue) {
                if (newValue != oldValue) {
                    // Init for new search results
                    this.batchSize = 0
                    this.nextBatch()
                }
            }/*,
            selectedGroup: function(){

            }*/
        },

        mounted() {
            if (this.page == 'search') {
                this.searchPage = true

                // Fetch all lightboxes of current user
                lightboxService.all(this.user)
                    .then(response => {
                        this.lightboxes = response.data
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('invalid_data')
                    })

                // Fetch image id's on all lightboxes of current user
                lightboxService.allItems({
                    user_id: this.user.id
                })
                    .then(response => {
                        this.onLightbox = response.data
                    })

            }
            if (this.page == 'lightbox') {
                this.lightboxPage = true
            }
            if (this.page == 'lightbox_ext') {
                this.lightboxExtPage = true
            }

            if (this.docs == 'images') {
                this.documentGroups = [...this.getDocumentGroups, ...this.getDocumentGroupsBranding]
            }
            if (this.docs == 'branding') {
                this.documentGroups = [...this.getDocumentGroupsBranding, ...this.getDocumentGroups]
            }
        },

        data() {
            return {
                searchPage: false,
                lightboxPage: false,
                lightboxExtPage: false,
                documentGroups: [],
                lightboxes: [],
                onLightbox: [],
                otherLightboxes: [],

                batchSize: 0,

                dialog: false,
                areyousure: false,
                lightboxDialog: false,
                swapDialog: false,

                selectedImage: null,
                selectedLightbox: null,
                selectedGroup: null,

                imageDetails: {
                    id: '',
                    brand: '',
                    name: '',
                    code: '',
                    cultivar: '',
                    variety: '',
                    published: '',
                    photography: '',
                    category: '',
                    shot: '',
                    document_group: '',
                    keywords: '',
                    image_file: '',
                    token: '',
                    extension: '',
                    thumbnail: '',
                    preview: '',
                    imagegroup: {'name':'','id':0},
                },
                remarkDetails: {
                    image_id: '',
                    user_id: '',
                    remark: '',
                    status: '',
                },

                dropzoneOptions: {
                    url: "/upload_preview",
                    acceptedFiles: "image/*",
                    thumbnailWidth: 150,
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictDefaultMessage: this.$t('select_thumbnail'),
                },

                snackbar: false,
                snackbarTop: true,
                snackbarColor: '',
                snackbarMessage: '',
            }
        },

        methods: {
            nextBatch() {
                this.batchSize += this.getBatchSize
            },

            showLightboxes(image) {
                this.lightboxDialog = true
                this.selectedImage = image
            },

            addItem() {
                this.lightboxDialog = false

                // Set current lightbox in store
                this.$store.dispatch('setLightbox', this.selectedLightbox)

                lightboxService.addItem({
                        lightbox_id: this.selectedLightbox.id,
                        image_id: this.selectedImage.id,
                    }
                )
                    .then(() => {
                        this.snackbar = true
                        this.snackbarColor = "success"
                        this.snackbarMessage = this.$t('added_to_lightbox')

                        this.onLightbox.push(this.selectedImage.id)

                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('invalid_data')
                    })
            },

            swapLightboxes(image) {
                this.selectedImage = image

                lightboxService.others({
                    user_id: this.user.id,
                    image_id: image.id,
                })
                    .then(response => {
                        this.otherLightboxes = response.data

                        if (this.otherLightboxes.length) {
                            this.swapDialog = true
                        }
                        else {
                            this.removeItem()
                        }
                    })
            },

            swapItem() {
                this.swapDialog = false

                lightboxService.swapItem({
                    user_id: this.user.id,
                    lightbox_id: this.selectedLightbox.id,
                    image_id: this.selectedImage.id,
                })
                    .then(() => {
                        this.snackbar = true
                        this.snackbarColor = "success"
                        this.snackbarMessage = this.$t('swapped_lightbox')

                        if (this.lightboxPage) {
                            let currentIndex = this.displayImages.findIndex(image => image.id === this.selectedImage.id)
                            this.displayImages.splice(currentIndex, 1)
                        }
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('invalid_data')
                    })
            },

            removeItem() {
                this.swapDialog = false

                lightboxService.removeItem({
                    user_id: this.user.id,
                    lightbox_id: this.lightbox.id,
                    image_id: this.selectedImage.id,
                })
                    .then(() => {
                        this.snackbar = true
                        this.snackbarColor = "success"
                        this.snackbarMessage = this.$t('removed_from_lightbox')

                        if (this.searchPage) {
                            let currentIndex = this.onLightbox.findIndex(item => item === this.selectedImage.id)
                            this.onLightbox.splice(currentIndex, 1)
                        }

                        if (this.lightboxPage) {
                            let currentIndex = this.displayImages.findIndex(image => image.id === this.selectedImage.id)
                            this.displayImages.splice(currentIndex, 1)
                        }
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('invalid_data')
                    })
            },

            isOnLightbox(image) {
                // Is this image on a lightbox of the current user?
                if (this.onLightbox.includes(image.id)) {
                    return true
                }
                return false
            },

            save() {
                this.dialog = false

                imageService.update(this.imageDetails)
                    .then(() => {
                        this.snackbar = true
                        this.snackbarColor = "success"
                        this.snackbarMessage = this.$t('data_saved')
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('invalid_data')
                    })
            },

            remove() {
                this.areyousure = false
                this.dialog = false

                imageService.remove(this.imageDetails)
                    .then(() => {
                        this.snackbar = true
                        this.snackbarColor = "success"
                        this.snackbarMessage = this.$t('image_deleted')

                        // Find index of this image
                        let currentImage = this.imageDetails
                        let currentIndex = this.displayImages.findIndex(image => image.id === currentImage.id)

                        this.displayImages.splice(currentIndex, 1)
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('invalid_data')
                    })
            },

            addRemark() {
                this.dialog = false

                this.remarkDetails.image_id = this.imageDetails.id
                this.remarkDetails.user_id = this.user.id
                this.remarkDetails.status = 'Requested'

                remarkService.save(this.remarkDetails)
                    .then(() => {
                        this.snackbar = true
                        this.snackbarColor = "success"
                        this.snackbarMessage = this.$t('remark_added')
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('invalid_data')
                    })
            },

            sendingEvent (file, xhr, formData) {
                formData.append('imageName', this.imageDetails.image_file);
            },
            markImageAsGroupImage (){
                groupService.setImage(this.imageDetails);
                this.imageDetails.imagegroup.image_id = this.imageDetails.id;
            },
            showGroup(group){
              if(this.page=='lightbox'){
                this.selectedGroup = group;
              }else{
                window.location= "/#/login";
              }
            },
          clearGroup(){
              this.selectedGroup = null;
          }
        },
    }
</script>
