<template>
    <div>
        <div v-if="extension == 'pdf'">
            <div>
                <v-btn text icon color="blue" :href="createLink('L')">
                    <v-icon>mdi-download</v-icon>
                </v-btn>
                <span>{{ $t('download_pdf') }}</span>
            </div>
        </div>
        <div v-else>
            <div>
                <v-btn text icon color="blue" :href="createLink('L')">
                    <v-icon>mdi-download</v-icon>
                </v-btn>
                <span>{{ $t('download_large') }}</span>
            </div>

            <div>
                <v-btn text icon color="blue" :href="createLink('M')">
                    <v-icon>mdi-download</v-icon>
                </v-btn>
                <span>{{ $t('download_medium') }}</span>
            </div>

            <div>
                <v-btn text icon color="blue" :href="createLink('S')">
                    <v-icon>mdi-download</v-icon>
                </v-btn>
                <span>{{ $t('download_small') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "DownloadLinks",
        props: ['extension', 'token'],

        computed: {
            ...mapState(['base', 'user']),
        },

        methods: {
            createLink(size) {
                let createdLink = this.base.url + '/downloads/download' +
                    '?image=' + this.token +
                    '&size=' + size +
                    '&user=' + this.user.token

                return createdLink
            },
        }
    }
</script>

