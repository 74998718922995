import apiClient from './config'
import store from './../store'

if (store.state.user) {
    apiClient.defaults.headers.common['Authorization'] = store.state.user.token
}

export default {
    all(data) {
        return apiClient.post('/brands', data)
    },

    active(data) {
        return apiClient.post('/brands/active', data)
    },

    favourite(data) {
        return apiClient.post('/brands/favourite', data)
    },

    rest(data) {
        return apiClient.post('/brands/rest', data)
    },

    save(data) {
        return apiClient.post('/brands/save', data)
    },
}
