<template>
    <v-container>
        <v-row v-if="favouriteBrands">
            <v-col class="text-center" v-for="brand in favouriteBrands" :key="brand.id">
                <v-btn text @click="searchByBrand(brand.name_en)">{{brand.name_en}}</v-btn>
            </v-col>

            <v-col class="text-center">
                <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text v-bind="attrs" v-on="on">
                            {{$t('other')}}
                            <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                                v-for="(brand, index) in restBrands"
                                :key="index"
                                @click="searchByBrand(brand.name_en)"
                        >
                            <v-list-item-title>{{brand.name_en}}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>

        <v-divider v-if="favouriteBrands"></v-divider>

        <v-row>
            <v-col>
                <v-card>

                    <v-form @submit.prevent="search">
                        <v-card-text>
                            <v-row>
                                <v-col cols="3">
                                    <v-autocomplete
                                            clearable="true"
                                            :label="$t('search_by_group')"
                                            :items="allowedDocumentGroups"
                                            v-model="documentGroup"
                                    >
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="3">
                                    <v-text-field
                                            clearable="true"
                                            :label="$t('search_by_keyword')"
                                            type="text"
                                            v-model="keywords"

                                    />
                                </v-col>

                                <v-col cols="3">
                                    <v-text-field
                                        clearable="true"
                                        :label="$t('search_by_variety')"
                                        type="text"
                                        v-model="variety"
                                    />
                                </v-col>

                                <v-col cols="3">
                                    <v-btn id="searchButton" type="submit" color="info" class="mt-4">
                                        <v-icon dark left>mdi-magnify</v-icon>
                                        {{ $t('search') }}
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row v-if="responseImages.length > 0">
                                <v-col cols="3">
                                    <v-checkbox
                                            v-model="showCloseUp"
                                            :label="closeUpLabel"
                                    >
                                    </v-checkbox>
                                </v-col>

                                <v-col cols="3">
                                    <v-checkbox
                                            v-model="showGroup"
                                            :label="groupLabel"
                                    >
                                    </v-checkbox>
                                </v-col>

                                <v-col cols="3">
                                    <v-checkbox
                                            v-model="showAtmosphere"
                                            :label="atmosphereLabel"
                                    >
                                    </v-checkbox>
                                </v-col>

                                <v-col cols="3">
                                    <v-checkbox
                                            v-model="showOther"
                                            :label="otherLabel"
                                    >
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-form>

                </v-card>
            </v-col>
        </v-row>

        <imagesList v-if="images" :images="images" :groups="groups" page="search" docs="images"></imagesList>

        <v-snackbar id="snackbar" :color=snackbarColor :top=snackbarTop v-model="snackbar">
            {{ snackbarMessage }}

            <v-btn text @click="snackbar=false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import {mapGetters, mapState} from 'vuex'
    import imageService from '@/services/ImageService.js'
    import brandService from '@/services/BrandService.js'
    import lightboxService from '@/services/LightboxService.js'
    import imagesList from '@/components/ImagesList'

    export default {
        name: 'Images',

        components: {
            imagesList
        },

        computed: {
            ...mapState(['user', 'lightbox', 'isLoggedIn', 'isVisitor']),
            ...mapGetters(['getName', 'getDocumentGroups']),
        },

        watch: {
            showCloseUp() {
                this.filter()
            },
            showGroup() {
                this.filter()
            },
            showAtmosphere() {
                this.filter()
            },
            showOther() {
                this.filter()
            },
        },

        created() {
            // Route guard
            if (!this.isLoggedIn) {
                this.$store.dispatch('logout')
                this.$router.push('/login')
            }

            this.init()
        },

        data() {
            return {
                keywords: '',
                allowedDocumentGroups: [],
                documentGroup: '',
                responseImages: [],
                images: [],
                groups: null,
                variety: '',
                favouriteBrands: [],
                restBrands: [],

                showCloseUp: true,
                showGroup: true,
                showAtmosphere: true,
                showOther: true,

                closeUpImages: [],
                closeUpCount: 0,
                closeUpLabel: '',

                groupImages: [],
                groupCount: 0,
                groupLabel: '',

                atmosphereImages: [],
                atmosphereCount: 0,
                atmosphereLabel: '',

                otherImages: [],
                otherCount: 0,
                otherLabel: '',

                snackbar: false,
                snackbarTop: true,
                snackbarColor: '',
                snackbarMessage: '',
            }
        },

        methods: {
            init() {
                this.allowedDocumentGroups = this.getDocumentGroups

                if (this.getName == "MNP") {
                    if (this.isVisitor) {
                        this.allowedDocumentGroups = this.getDocumentGroups
                            .filter(group => group != "Pre-commercial")
                            .filter(group => group != "Test")
                            .filter(group => group != "Intro candidates")
                    }
                }

                brandService.favourite()
                    .then(response => {
                        this.favouriteBrands = response.data
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('invalid_data')
                    })

                brandService.rest()
                    .then(response => {
                        this.restBrands = response.data
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('invalid_data')
                    })

                // Check if there is a current lightbox. If not: create a default one
                lightboxService.findLast({
                    user_id: this.user.id
                })
                    .then(response => {
                        // Set lightbox in store
                        this.$store.dispatch('setLightbox', response.data)
                    })

                this.splash()
            },

            splash() {
                imageService.splash({
                    isVisitor: this.isVisitor,
                    isBranding: false,
                })
                    .then(response => {
                        this.responseImages = response.data

                        this.group()
                        this.filter()
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('no_results')

                        this.images = []
                    })
            },

            search() {
                // Init
                this.images = []
                this.closeUpImages = []
                this.groupImages = []
                this.atmosphereImages = []
                this.otherImages = []

                this.closeUpCount = 0
                this.groupCount = 0
                this.atmosphereCount = 0
                this.otherCount = 0

                imageService.all(
                    {
                        variety: this.variety,
                        documentGroup: this.documentGroup,
                        keywords: this.keywords,
                        isBranding: false,
                    }
                )
                    .then(response => {
                        this.responseImages = response.data

                        if (this.getName == "MNP") {
                            if (this.isVisitor) {
                                let allowedImages = this.responseImages
                                    .filter(image => image.published == "Yes")
                                    .filter(image => image.category == "Public")
                                    .filter(image => image.document_group != "Pre-commercial")
                                    .filter(image => image.document_group != "Test")
                                    .filter(image => image.document_group != "Intro candidates")
                                this.responseImages = allowedImages
                            }
                        }

                        this.group()
                        this.filter()
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('no_results')

                        this.images = []
                    })
            },

            searchByBrand(brand) {
                this.keywords = brand
                this.search()
            },

            group() {
                // Close-up
                this.closeUpImages = this.responseImages
                    .filter(image => image.shot == "Close-up")
                this.closeUpCount = this.closeUpImages.length
                this.closeUpLabel = this.$t('close_up') + " " + "(" + this.closeUpCount + ")"

                // Group
                this.groupImages = this.responseImages
                    .filter(image => image.shot == "Group" || image.shot == "Group/Mix")
                this.groupCount = this.groupImages.length
                this.groupLabel = this.$t('group') + " " + "(" + this.groupCount + ")"

                // Atmosphere
                this.atmosphereImages = this.responseImages
                    .filter(image => image.shot == "Atmosphere" || image.shot == "Ambience")
                this.atmosphereCount = this.atmosphereImages.length
                this.atmosphereLabel = this.$t('atmosphere') + " " + "(" + this.atmosphereCount + ")"

                // Other
                this.otherImages = this.responseImages
                    .filter(image => image.shot != "Close-up" && image.shot != "Group" && image.shot != "Group/Mix" && image.shot != "Atmosphere" && image.shot != "Ambience")
                this.otherCount = this.otherImages.length
                this.otherLabel = this.$t('other') + " " + "(" + this.otherCount + ")"
            },

            filter() {
                // Init
                this.images = []

                // Filter "Close-up"
                if (this.showCloseUp) {
                    this.images = [...this.images, ...this.closeUpImages]
                }

                // Filter "Group"
                if (this.showGroup) {
                    this.images = [...this.images, ...this.groupImages]
                }

                // Filter "Atmosphere"
                if (this.showAtmosphere) {
                    this.images = [...this.images, ...this.atmosphereImages]
                }

                // Filter "Other"
                if (this.showOther) {
                    this.images = [...this.images, ...this.otherImages]
                }

                // Sort
                this.images.sort(function(a, b) {
                    // NULL values should be replaced
                    if (!a.variety) {
                        a.variety = ''
                    }
                    if (!b.variety) {
                        b.variety = ''
                    }

                    let varietyA = a.variety.toUpperCase()
                    let varietyB = b.variety.toUpperCase()

                    if (varietyA < varietyB) {
                        return -1
                    }
                    if (varietyA > varietyB) {
                        return 1
                    }
                    return 0
                })
            },
        },
    }
</script>
